import React from "react";
import Constant from "../../common/Constant";
import ErrorMessage from "../../common/ErrorMessage";
import ProgramDeliveryModes from "../ui/ProgramDeliveryModes";
import SelectMultiStateTerritory from "../ui/SelectMultiStateTerritory";
import DeliveryAttestation from "../ui/DeliveryAttestation";
import SocialVulnerabilityIndex from "../ui/SocialVulnerabilityIndex";
import EnrollmentLocation from "../ui/EnrollmentLocation";
import RequiredSpanText from "../../common/RequiredSpanText";

const TypeOfDelivery = ({
    applicationObj,
    statesData,
    programDeliveryMode,
    combineDeliveryModes,
    interactionMechanismLku,
    technologyLookUp,
    onChangeTypeOfDelivery,
    onStateSelectChange,
    onDeliveryModeCheckboxChange,
    onChange,
    onCheckboxChange,
    onIncludeEnrollmentLocations,
    changeSocialVulnerabilityIndexCheckbox,
    onOtherInputChange,
    onValidateBlur,
    errors
}) => {
    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary">Type of Delivery</h2>
            <div className="card-body">
                <ProgramDeliveryModes
                    applicationObj={applicationObj}
                    programDeliveryMode={programDeliveryMode}
                    onChangeTypeOfDelivery={onChangeTypeOfDelivery}
                    errors={errors}
                    name="DeliveryModeId"
                    title="Delivery Mode"
                />
            <div className="mb-4 ml-3">
                <small>{'\u2020'} Organizations delivering or wanting to deliver the MDPP may only deliver using these modes.</small>
            </div>
                {/*type of delivery is combination: 4*/}
                {applicationObj[`${Constant.DELIVERY_MODE}`] === "4" && ( //eslint-disable-line
                    <div role="group" aria-labelledby="question-heading">
                        <span id="question-heading" className="h6 my-2">Combination with an Online Component Delivery</span>
                        <div className="ml-3">
                            <small>{Constant.DELIVERY_MODE_COMBINATION_INTRO_TXT}</small>
                        </div>
                        <div className="ml-3 mt-2 mb-4">
                            <small>
                                Examples of an acceptable delivery model include:
                                <ul className="list-letter-style">
                                    <li>The use of one modality such as in-person and/or distance learning in the Core phase of the program and online in the Core Maintenance phase.</li>
                                    <li>A consistent delivery approach using two or three modalities for each participant in a rotating manner (e.g., one session in-person and the next session online, or 5 sessions online and the next 5 sessions via distance learning).</li>
                                    <li>Entire cohorts conducted using different delivery modes (one cohort in-person and/or via distance learning and another cohort online).</li>
                                    <li>Some participants within a cohort using one delivery mode and some participants within the same cohort using the other.</li>
                                    <li>Participants choose from session to session which mode they wish to use.</li>
                                </ul>
                            </small>
                        </div>
                        <div className="ml-3">
                            <RequiredSpanText text={'Which modes in addition to online will you be using? '}/>
                        </div>
                        {combineDeliveryModes.map(mode => {
                            //eslint-disable-line
                            return (
                                <div key={mode.id} className="ml-3 custom-control" >
                                    <input
                                        type="checkbox"
                                        className="big-checkbox"
                                        onChange={onDeliveryModeCheckboxChange}
                                        name={Constant.PARTICIPANT_DELIVERY_MODE_IDS}
                                        data-deliverymodename={mode.name}
                                        data-deliverymodevalue={mode.id}
                                        id={mode.id}
                                        disabled={mode.id === 2 ? true : false}
                                        checked={mode.id === 2 ? true : undefined}
                                    />
                                    <label className="pl-3" htmlFor={mode.id}>
                                        {mode.name}
                                    </label>
                                </div>
                            );
                        })}
                        {applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`]
                            .length < 1 && (
                                <ErrorMessage
                                    className="col-6"
                                    errorMessage={Constant.COMBINATION_DELIVERY_MODE_VALIDATE_MSG}
                                />
                            )
                        }
                        {applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].includes(1) && (
                            <>
                                <span id="question-heading" className="h6 my-2">In-person Modality</span>
                                <p className="ml-3">
                                    <small>
                                        {Constant.DELIVERY_MODE_IN_PERSON_INTRO_TXT}
                                    </small>
                                </p>
                            </>
                        )}
                        {applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].includes(3) === true && (
                            <>
                                <span id="question-heading" className="h6 my-2">Distance Learning (live) Modality</span>
                                <p className="ml-3">
                                    <small>
                                        {Constant.DELIVERY_MODE_DISTANCE_LEARNING_INTRO_TXT}
                                    </small>
                                </p>
                            </>
                        )} 
                        <span id="question-heading" className="h6 my-2">Online (non-live) Modality</span>
                        <p className="ml-3">
                            <small>
                                {Constant.DELIVERY_MODE_ONLINE_INTRO_TXT}
                            </small>
                        </p>                       
                        <div className="mb-3">
                            <DeliveryAttestation 
                                name={Constant.DELIVERY_ATTESTATION}
                                title={Constant.DELIVERY_ATTESTATION_COMBINATION_LABEL}
                                change={onCheckboxChange}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                        </div>
                        <fieldset>
                            <legend className="h6 mb-0">Enrollment Location</legend>
                            <div className="mb-3">
                                <EnrollmentLocation
                                    title={Constant.ENROLLMENT_LOCATION}
                                    onChange={onIncludeEnrollmentLocations}
                                    applicationObj={applicationObj}
                                    errors={errors}
                                />
                                <div className="ml-3">
                                    {
                                    (applicationObj[`${Constant.DELIVERY_MODE}`] === "4" &&
                                    applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() === "false") &&
                                    (
                                        <SelectMultiStateTerritory
                                            onChange={onChange}
                                            onStateSelectChange={onStateSelectChange}
                                            applicationObj={applicationObj}
                                            statesData={statesData}
                                            errors={errors}
                                        />
                                    )}
                                </div>
                            </div>
                        </fieldset>
                        <div className="mb-3">
                            <SocialVulnerabilityIndex 
                                title={Constant.SOCIAL_VULNERABILITY_INDEX_LABEL}
                                change={changeSocialVulnerabilityIndexCheckbox}
                                applicationObj={applicationObj}
                            />
                        </div>
                    </div>
                )}
                {/*end type of delivery is combination: 4*/}
                {/*type of delivery is InPerson: 1*/}
                {(applicationObj[`${Constant.DELIVERY_MODE}`] === "1") && (
                    <>
                        <span className="h6 my-2">In-person Delivery</span>
                        <div className="ml-3">
                            <small>{Constant.DELIVERY_MODE_IN_PERSON_INTRO_TXT}</small>
                        </div>
                        <div className="mb-3">
                            <DeliveryAttestation 
                                name={Constant.DELIVERY_ATTESTATION}
                                title={Constant.DELIVERY_ATTESTATION_IN_PERSON_LABEL}
                                change={onCheckboxChange}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                        </div>
                        <div className="mb-3">
                            <SocialVulnerabilityIndex 
                                title={Constant.SOCIAL_VULNERABILITY_INDEX_LABEL}
                                change={onCheckboxChange}
                                applicationObj={applicationObj}
                            />
                        </div>
                    </>
                )}
                {/*end type of delivery is InPerson: 1*/}

                {/* type of delivery is online: 2*/}
                {(applicationObj[`${Constant.DELIVERY_MODE}`] === "2") && (
                    <>
                        <span className="h6 my-2 mt-3">Online (non-live) Delivery</span>
                        <p>{Constant.DELIVERY_MODE_ONLINE_INTRO_TXT}</p>
                        <div className="mb-3">
                            <DeliveryAttestation 
                                name={Constant.DELIVERY_ATTESTATION}
                                title={Constant.DELIVERY_ATTESTATION_ONLINE_NON_LIVE_LABEL}
                                change={onCheckboxChange}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                        </div>
                        <fieldset>
                            <legend className="h6 mb-0">Enrollment Location</legend>
                            <EnrollmentLocation
                                title={Constant.ENROLLMENT_LOCATION}
                                onChange={onIncludeEnrollmentLocations}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                            <div className="ml-3">
                                {applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() === "false" &&
                                (
                                    <div className="mb-3">
                                        <SelectMultiStateTerritory
                                            onChange={onChange}
                                            onStateSelectChange={onStateSelectChange}
                                            applicationObj={applicationObj}
                                            statesData={statesData}
                                            errors={errors}
                                        />
                                    </div>
                                )}
                            </div>
                        </fieldset>
                        <div className="mb-3">
                            <SocialVulnerabilityIndex 
                                title={Constant.SOCIAL_VULNERABILITY_INDEX_LABEL}
                                change={changeSocialVulnerabilityIndexCheckbox}
                                applicationObj={applicationObj}
                            />
                        </div>
                    </>
                )}
                {/*end type of delivery is online: 2*/}

                {/*type of delivery is distance learning: 3*/}
                {(applicationObj[`${Constant.DELIVERY_MODE}`] == "3") && ( //eslint-disable-line
                    <>
                        <span className="h6 my-2">
                            Distance Learning (live) Delivery
                        </span>
                        <p className="ml-3"><small>{Constant.DELIVERY_MODE_DISTANCE_LEARNING_INTRO_TXT}</small></p>
                        <div className="mb-3">
                            <DeliveryAttestation 
                                name={Constant.DELIVERY_ATTESTATION}
                                title={Constant.DELIVERY_ATTESTATION_DISTANCE_LEARNING_LABEL}
                                change={onCheckboxChange}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                        </div>
                        <fieldset>
                            <legend className="h6 mb-0">Enrollment Location</legend>
                            <EnrollmentLocation
                                title={Constant.ENROLLMENT_LOCATION}
                                onChange={onIncludeEnrollmentLocations}
                                applicationObj={applicationObj}
                                errors={errors}
                            />
                            <div className="ml-3">
                                {
                                (applicationObj[`${Constant.DELIVERY_MODE}`] === "3" &&
                                applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() === "false") &&
                                (
                                    <SelectMultiStateTerritory
                                        onChange={onChange}
                                        onStateSelectChange={onStateSelectChange}
                                        applicationObj={applicationObj}
                                        statesData={statesData}
                                        errors={errors}
                                    />
                                )}
                            </div>
                        </fieldset>
                        <div className="mb-3">
                            <SocialVulnerabilityIndex 
                                title={Constant.SOCIAL_VULNERABILITY_INDEX_LABEL}
                                change={changeSocialVulnerabilityIndexCheckbox}
                                applicationObj={applicationObj}
                            />
                        </div>
                    </>
                )}
                {/*end type of delivery is distance learning: 3*/}

                {/*type of delivery is combination 5*/}
                {applicationObj[`${Constant.DELIVERY_MODE}`] === "5" && (
                    <>
                        <span className="h6 my-2">{Constant.DELIVERY_MODE_IN_PERSON_DISTANCE_LEARNING}</span>
                        <div className="ml-3">
                            <small>{Constant.DELIVERY_MODE_IN_PERSON_DISTANCE_LEARNING_TEXT}</small>
                            <div className="mt-2">
                                <small>
                                    Examples of an acceptable delivery model for in-person with a distance learning component include:
                                    <ul className="list-letter-style">
                                        <li>A combination of in-person and distance learning during the Core and Core Maintenance phases.</li>
                                        <li>Some participants within a cohort using the in-person delivery mode and some participants using the distance learning delivery mode.</li>
                                        <li>Participants choose from session to session which mode (in-person or distance learning) they wish to use.</li>
                                    </ul>
                                </small>
                            </div>
                            <div className="mb-3">
                                <DeliveryAttestation 
                                    name={Constant.DELIVERY_ATTESTATION}
                                    title={Constant.DELIVERY_ATTESTATION_IN_PERSON_DISTANCE_LEARNING_LABEL}
                                    change={onCheckboxChange}
                                    applicationObj={applicationObj}
                                    errors={errors}
                                />
                            </div>
                            <fieldset>
                                <legend className="h6 mb-0">Enrollment Location</legend>
                                <EnrollmentLocation
                                    title={Constant.ENROLLMENT_LOCATION}
                                    onChange={onIncludeEnrollmentLocations}
                                    applicationObj={applicationObj}
                                    errors={errors}
                                />
                                <div className="ml-3">
                                    {
                                    (applicationObj[`${Constant.DELIVERY_MODE}`] === "5" &&
                                    applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() === "false") &&
                                    (
                                        <SelectMultiStateTerritory
                                            onChange={onChange}
                                            onStateSelectChange={onStateSelectChange}
                                            applicationObj={applicationObj}
                                            statesData={statesData}
                                            errors={errors}
                                        />
                                    )}
                                </div>
                            </fieldset>
                            <div className="mb-3">
                                <SocialVulnerabilityIndex 
                                    title={Constant.SOCIAL_VULNERABILITY_INDEX_LABEL}
                                    change={changeSocialVulnerabilityIndexCheckbox}
                                    applicationObj={applicationObj}
                                />
                            </div>
                        </div>
                    </>
                )}
                {/*end type of delivery is In-personwith Distance learning Component*/}
            </div>
        </div>
    );
};

export default TypeOfDelivery;
