import React, { Component } from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import Application from './components/container/Application.jsx';
import Registry from './components/container/Registry.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationPage from './components/ui/ConfirmationPage.jsx';

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<Application />} />
          <Route path="/application" caseSensitive element={<Application />} />
          <Route path="/registry" element={<Registry />} />
          <Route path="/confirmationpage" element={<ConfirmationPage />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
          <Route
            path="/cms/download"
            element={<Navigate to="/registry" replace={true} />}
          />
        </Routes>
        <ToastContainer hideProgressBar />
      </>
    );
  }
}
