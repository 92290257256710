import React from 'react';

const City = ({
  onChange,
  value,
  cityData,
  name,
  label,
  allStatesSelected,
}) => {
  return (
    <div className="form-group">
      <label className="mr-3" htmlFor={name}>
        {label}
      </label>
      <select
        onChange={onChange}
        name={name}
        value={value}
        id={name}
        className="form-control"
        disabled={allStatesSelected && 'disabled'}
        aria-disabled={allStatesSelected && 'true'}
      >
        {allStatesSelected ? (
          <option value="na">N/A</option>
        ) : (
          <option value="all">All</option>
        )}
        {cityData.map((city, index) => {
          return (
            <option key={index} value={city}>
              {city}
            </option>
          );
        })}
      </select>{' '}
    </div>
  );
};

export default City;
