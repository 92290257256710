import React from 'react';

const TextInput = ({
  accessibleHideLabel = false,
  containerClassName = '',
  inlineText,
  inputClassName,
  inputAdditionalStyling = '',
  errorAdditionalStyling = '',
  extraTextStyling = '',
  isPhoneNumber = false,
  isDate = false,
  error,
  errorClassName,
  extraText,
  labelClassName,
  labelText,
  maxLength,
  name,
  onChange,
  onKeyDown,
  onBlurPhoneNumber,
  onValidateBlur,
  phoneNumberControl,
  phoneNumberAreaCode,
  phoneNumber3,
  phoneNumber4,
  minStartDate,
  requiredLabel = false,
  validateMsg = '',
  value,
}) => {
  let wrapperClass = 'form-group';
  if (String(error).length > 0) {
    wrapperClass += ' has-error mb-0';
  }
  return (
    <div className={wrapperClass + containerClassName}>
      <label htmlFor={name} className={labelClassName + ' mt-1'}>
        <span className={`${accessibleHideLabel && 'accessible-hide'}`}>
          {labelText}
        </span>
        {requiredLabel && <span className="text-red">*</span>}
      </label>{' '}
      {extraText && (
        <div className={`${extraTextStyling} mb-3 small-subtext`}>
          {extraText}
        </div>
      )}
      <div className="mt-1">
        {isPhoneNumber ? (
          <input
            id={name}
            name={name}
            className={
              'form-control ' + inputClassName + ' ' + inputAdditionalStyling
            }
            maxLength={maxLength}
            type="tel"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={() => {
              onBlurPhoneNumber(
                phoneNumberControl,
                phoneNumberAreaCode,
                phoneNumber3,
                phoneNumber4
              );
            }}
            value={value}
            autoComplete="off"
          />
        ) : isDate ? (
          <>
            <input
              id={name}
              name={name}
              type="date"
              className={
                'form-control ' + inputClassName + ' ' + inputAdditionalStyling
              }
              onChange={onChange}
              min={minStartDate}
              onBlur={onValidateBlur}
              value={value}
            />
          </>
        ) : (
          <>
            <input
              id={name}
              data-validatemsg={validateMsg}
              name={name}
              className={
                'form-control ' + inputClassName + ' ' + inputAdditionalStyling
              }
              maxLength={maxLength}
              onChange={onChange}
              onBlur={onValidateBlur}
              type="text"
              autoComplete="off"
              value={value}
            />
            {inlineText && <span>{inlineText}</span>}
          </>
        )}
        {error && (
          <div
            className={`alert alert-danger ${errorClassName} ${errorAdditionalStyling}`}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
