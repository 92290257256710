import React from 'react';
import Select, { components } from 'react-select';
import Constant from '../../common/Constant';

function States({
  statesData,
  onChange,
  value,
  name,
  allStatesSelected = false,
  defaultOption,
  requiredLabel = false,
  selectClassName = 'col-5',
  error,
  errorClassName,
}) {
  const Input = (inputProps) => {
    // Needed to address 508 issue of default react-select input role being combobox and not listbox
    return (
      <components.Input
        {...inputProps}
        role="listbox"
        aria-autocomplete={undefined} // role list box does not use aria-autocomplete property -- critical 508 issue
      />
    );
  };
  return (
    <>
      <div className="form-group col-5 pl-0">
        <label id="state-label" htmlFor={name} className="mr-3">
          State {requiredLabel && <span className="text-red">*</span>}
        </label>
        <div className="state-select-width">
          <Select
            defaultValue={defaultOption.id}
            name={name}
            id={name}
            aria-label={'Select State'}
            options={statesData}
            onChange={(value) => onChange({ name: name, value: value.id })}
            value={value}
            components={{ Input }}
            defaultOption={defaultOption}
            getOptionLabel={(option) => option.description}
            getOptionValue={(option) => option.id}
            styles={Constant.COLOR_STYLES}
            isOptionDisabled={(option) =>
              option.description.toLowerCase() === 'us states' ||
              option.description.toLowerCase() === 'us territories' ||
              option.description.toLowerCase() === 'freely associated states'
            }
            classNamePrefix="select"
          />
        </div>
        {error && (
          <div className={`alert alert-danger ${errorClassName}`}>{error}</div>
        )}
      </div>
    </>
  );
}

export default States;
