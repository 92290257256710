import React from 'react';

const RequiredSpanText = ({ text }) => (
  <span>
    {text}
    <span className="text-red">*</span>
  </span>
);

export default RequiredSpanText;
