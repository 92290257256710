import React from 'react';
import RequiredSpanText from '../../common/RequiredSpanText';

function RoleTitles({
  roleTitles,
  loadingApiStatus,
  secondaryLabel,
  onChange,
  name,
  defaultOption = 'Select one...',
  value,
  error,
}) {
  return (
    <div>
      {loadingApiStatus ? (
        <h2>
          <strong>Loading Role titles Data....</strong>
        </h2>
      ) : (
        <>
          <span className="mt-2">
            <label htmlFor={name}>
              Title/Affiliation <RequiredSpanText />
            </label>
          </span>
          <div className="form-group mb-0">
          <small>{secondaryLabel}</small>
            <select
              className="form-control col-3 mt-2"
              id={name}
              onChange={onChange}
              value={value}
              name={name}
            >
              <option>{defaultOption}</option>
              {roleTitles.map((lookUpItem) => {
                return (
                  <option key={lookUpItem.id} value={lookUpItem.id}>
                    {lookUpItem.name}
                  </option>
                );
              })}
            </select>
            {error && <div className={`alert alert-danger`}>{error}</div>}
          </div>
        </>
      )}
    </div>
  );
}

export default RoleTitles;
