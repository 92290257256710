import React, { useState } from "react";
import States from "../ui/State.jsx";
import { FaCheck } from "react-icons/fa";

import TextInput from "../../common/TextInput.jsx";
import OrganizationCategories from "../ui/OrganizationCategories.jsx";
import Constant from "../../common/Constant";
import AddressTips from "../../common/AddressTips.jsx";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";

const GeneralInfo = ({
    statesData,
    stateValue,
    stateName,
    stateDefaultOption,
    onChange,
    onCheckboxChange,
    onKeyDown,
    onValidateBlur,
    onStateChange,
    onCheckUrlClick,
    onBlurPhoneNumber,
    applicationObj,
    addressObj,
    organizationCategories,
    onClickVerifyAddress,
    isVerifiedAddress,
    verifyingAddress,
    isValidUrl,
    errors
}) => {
    const [showAddressTips, setShowAddressTips] = useState(false);

    return (
        <div>
            {showAddressTips && <AddressTips {...{ setShowAddressTips }} />}
            <div className="generalInfo card">
                <h2 className="h4 card-header bg-primary">General Information</h2>
                <div className="card-body">
                    {/*Type of application */}
                    <div>
                        <span className="h5 font-weight-bold">
                            Type of Application<span className="text-red">*</span>
                        </span>
                        <div className="form-inline ml-3" role="group" aria-label="type of application">
                            <label className="mr-3">
                                <input
                                    type="radio"
                                    name={Constant.REAPPLY}
                                    onChange={onChange}
                                    value={false}
                                    className="form-control mr-1"
                                    defaultChecked={!applicationObj[`${Constant.REAPPLY}`] ? true : false}
                                    autoComplete="off"
                                    aria-checked={!applicationObj[`${Constant.REAPPLY}`] ? true : false}
                                />
                                Initial
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name={Constant.REAPPLY}
                                    onChange={onChange}
                                    value={true}
                                    className="form-control mr-1"
                                    autoComplete="off"
                                    defaultChecked={applicationObj[`${Constant.REAPPLY}`] ? true : false}
                                    aria-checked={applicationObj[`${Constant.REAPPLY}`] ? true : false}
                                />
                                Re-Apply
                            </label>
                        </div>
                        {errors[`${Constant.REAPPLY}`] && (
                            <div className={`alert alert-danger col-3 ml-3`}>
                                {errors[`${Constant.REAPPLY}`]}
                            </div>
                        )}
                        {applicationObj[`${Constant.REAPPLY}`] === true && (
                            <TextInput
                                onChange={onChange}
                                onValidateBlur={onValidateBlur}
                                value={
                                    applicationObj[`${Constant.PREV_ASSIGNED_ORG_CODE}`] || ""
                                }
                                labelClassName=""
                                labelText="Previous OrgCode Assigned"
                                extraText=""
                                name={Constant.PREV_ASSIGNED_ORG_CODE}
                                inputClassName=""
                                containerClassName="col-3"
                                requiredLabel=""
                                error={errors && errors[`${Constant.PREV_ASSIGNED_ORG_CODE}`]} //submit validate text
                                validateMsg={Constant.PREV_ASSIGNED_ORG_CODE_VALIDATE_MSG} //onblur validate text
                            />
                        )}
                    </div>
                    {/*End Type of application */}

                    {/*Organization Name */}
                    <div>
                        <span htmlFor="OrgName" className="h5 font-weight-bold">
                            Organization Name<span className="text-red">*</span>
                        </span>
                        <TextInput
                            onChange={onChange}
                            onValidateBlur={onValidateBlur}
                            inputAdditionalStyling={'ml-3'}
                            value={applicationObj[`${Constant.ORG_NAME}`] || ""}
                            labelClassName="accessible-hide"
                            labelText="Organization Name"
                            extraText={<>
                            <p className="ml-3">
                                Upon application approval, the organization name will be published in the DPRP registry at:
                                <NavLink target="_blank" tag={Link} className="link-underline d-inline pl-1 pr-1" to="/registry">
                                    Diabetes Prevention Recognition Program Registry | CDC
                                </NavLink>
                                and in the CDC Find a Program locator at:
                                <a target="_blank" rel="noreferrer" className="pl-1" href="https://www.cdc.gov/diabetes-prevention/lifestyle-change-program/find-a-program.html">
                                    Find a Program | Diabetes | CDC
                                </a>
                                .
                            </p>
                            </>}
                            name={Constant.ORG_NAME}
                            inputClassName="col-4"
                            containerClassName="ml-3 mt-2"
                            maxLength="100"
                            error={errors[`${Constant.ORG_NAME}`]}
                            errorClassName="col-4"
                            validateMsg={Constant.ORG_NAME_VALIDATE_MSG}
                        />
                    </div>
                    {/*End Organization Name */}

                    {/*Address information */}
                    <div className="mt-3" role="group" aria-label="organization physical address (no p.o. boxes)">
                        <span className="h5 font-weight-bold">
                            Organization Physical Address (No P.O. Boxes)
                        </span>
                        <p className="ml-3 my-2">
                            <small>
                                Provide the main organization’s business office or headquarters address. Upon application approval,
                                this will be published in the DPRP Registry and in the CDC Find a Program locator.
                            </small>
                        </p>
                        <div className="ml-3">
                            <TextInput
                                onChange={onChange}
                                onValidateBlur={onValidateBlur}
                                value={applicationObj.PhysicalAddress || ""}
                                labelText="Street Address"
                                name={Constant.PHYSICAL_ADDRESS}
                                inputClassName="col-4"
                                maxLength="200"
                                requiredLabel={true}
                                error={errors[`${Constant.PHYSICAL_ADDRESS}`]}
                                errorClassName="col-4"
                                validateMsg={Constant.PHYSICAL_ADDRESS_VALIDATE_MSG}
                            />
                            <TextInput
                                onChange={onChange}
                                value={applicationObj.PhysicalAddress2 || ""}
                                labelText="Street Address Line 2"
                                name={Constant.PHYSICAL_ADDRESS2}
                                inputClassName="col-4 mb-3"
                                maxLength="200"
                            />

                            <TextInput
                                onChange={onChange}
                                onValidateBlur={onValidateBlur}
                                value={applicationObj.PhysicalCity || ""}
                                labelText="City"
                                name={Constant.PHYSICAL_CITY}
                                inputClassName="col-4"
                                maxLength="100"
                                requiredLabel={true}
                                error={errors[`${Constant.PHYSICAL_CITY}`]}
                                errorClassName="col-4"
                                validateMsg={Constant.PHYSICAL_CITY_VALIDATE_MSG}
                            />

                            <div className="mt-3 form-group pl-0">
                                <States
                                    statesData={statesData}
                                    onChange={onStateChange}
                                    value={statesData.find(state => state.id === stateValue) || 0}
                                    name={stateName}
                                    defaultOption={stateDefaultOption}
                                    requiredLabel={true}
                                    error={errors[`${Constant.PHYSICAL_STATE_ID}`]}
                                    errorClassName="col-5"
                                />
                            </div>

                            <label htmlFor="PhysicalZip4" className="mt-3">
                                Zip code
                                <span className="text-red">*</span>
                            </label>
                            <div className="mt-1 form-inline">
                                <label
                                    htmlFor={Constant.PHYSICAL_ZIP5}
                                    className="accessible-hide"
                                >
                                    Zip code
                                </label>
                                <input
                                    id={Constant.PHYSICAL_ZIP5}
                                    name={Constant.PHYSICAL_ZIP5}
                                    className="form-control col-1 mr-3"
                                    maxLength="5"
                                    onChange={onChange}
                                    onBlur={onValidateBlur}
                                    value={applicationObj[`${Constant.PHYSICAL_ZIP5}`] || ""}
                                    data-validatemsg={Constant.PHYSICAL_ZIP5_VALIDATE_MSG}
                                    autoComplete="off"
                                />
                                {" - "}
                                <label htmlFor="physicalZipExt" className="accessible-hide">
                                    Zip code extension
                                </label>
                                <input
                                    id="physicalZipExt"
                                    name={Constant.PHYSICAL_ZIP4}
                                    className="form-control col-1 ml-3"
                                    maxLength="4"
                                    onChange={onChange}
                                    value={applicationObj[`${Constant.PHYSICAL_ZIP4}`] || ""}
                                    autoComplete="off"
                                />
                            </div>
                            {errors[`${Constant.PHYSICAL_ZIP5}`] && (
                                <div className={`alert alert-danger col-4`}>
                                    {errors[`${Constant.PHYSICAL_ZIP5}`]}
                                </div>
                            )}
                        </div>

                        {/*Verify Address*/}
                        {isVerifiedAddress && (
                            <div className="col-8 mb-3 ml-3 pl-0 row">
                                <div
                                    className="bt-4 bt-green-s card col-6 mt-0"
                                    style={{ backgroundColor: "#E7F3D5" }}
                                >
                                    <div className="bg-green-q card-body row">
                                        <div className="col-2">
                                            <FaCheck size="2em" className="text-success" />
                                        </div>
                                        <div className="col-10">
                                            Address verified: <br />
                                            {`${addressObj.address1}`} <br />
                                            {addressObj.address2 && (
                                                <>
                                                    {addressObj.address2} <br />
                                                </>
                                            )}
                                            {`${addressObj.city}, ${addressObj.state} ${addressObj.zip5
                                                }`}
                                            {addressObj.zip4 !== "" && `-${addressObj.zip4}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isVerifiedAddress && (
                            <div className="col-8 mt-3 ">
                                {isVerifiedAddress === null ? (
                                    <><small>Please verify address to continue.</small></>
                                ) : (
                                    <>
                                        Address not found.
                                        <br />
                                        Please update address.
                                    </>
                                )}
                            </div>
                        )}
                        {/*End Verify Address*/}
                        <div className="form-check-inline">
                            <button
                                onClick={onClickVerifyAddress}
                                className="btn btn-primary ml-3 mr-3 mt-3"
                            >
                                {verifyingAddress ? "Verifying..." : "Verify Address"}
                            </button>
                            <AddressTips />
                        </div>
                    </div>
                    {/*End address information*/}
                </div>

                {/*Organization url*/}
                <div className="mt-3 card-body">
                    <span className="h5 font-weight-bold">
                        Organization Web Address or URL (optional)
                    </span>

                    <label htmlFor={Constant.WEBADDRESS} className="accessible-hide mt-3">
                        Organization Web Address or URL (optional)
                    </label>

                    <div className="mt-1 ml-3">
                        <p className="mb-2">
                            <small>
                                Upon application approval, this will be published on the DPRP Registry
                                and in the CDC Find a Program locator. 
                            </small>
                        </p>
                        <div className="form-inline">
                            <input
                                id={Constant.WEBADDRESS}
                                name={Constant.WEBADDRESS}
                                className="form-control col-5 mr-3"
                                onChange={onChange}
                                value={applicationObj[`${Constant.WEBADDRESS}`] || ""}
                                autoComplete="off"
                            />
                            <button className="btn btn-primary" onClick={onCheckUrlClick}>
                                Check URL
                            </button>
                        </div>
                        {isValidUrl === false && (
                            <div className={`alert alert-danger col-5`}>
                                {Constant.INVALID_URL_VALIDATE_MSG}
                            </div>
                        )}
                        <p className="mt-2">
                            <small className="col-8 pl-0">
                                Note: All web addresses must link directly to a location where participants can find
                                information about the organization’s CDC-recognized National Diabetes Prevention Program (National DPP)
                                Lifestyle Change Program (LCP) and enroll in the
                                program. CDC will not accept or host any other web addresses, including those that appear
                                to endorse commercial products or services, diets, or dietary supplements in association
                                with the National DPP LCP. Applications with web addresses promoting such products/services
                                will be rejected.
                            </small>
                        </p>
                    </div>
                </div>
                {/*End Organization url*/}

                {/*Organization phone number*/}
                <div className="mt-3 card-body" role="group" aria-label="organization phone number">
                    <span className="h5 font-weight-bold">
                        Organization Phone Number<span className="text-red">*</span>
                    </span>

                    <label htmlFor="OrgPhoneNumber" className="accessible-hide mt-3">
                        Organization Phone Number
                    </label>

                    <div className="mt-1 ml-3">
                        <p className="mb-2 col-11 pl-0">
                            <small>
                                Provide the number that participants, payers, and others should call to obtain information
                                about the program. Organizations should not provide a 1-800 number unless a live operator
                                is available. Upon application approval, this phone number will be published in the DPRP
                                Registry and in the CDC Find a Program locator.
                            </small>
                        </p>
                        <div className="form-inline">
                            <input
                                id="OrgPhoneNumber"
                                name={Constant.ORG_PHONENUMBER}
                                className="form-control col-2 mr-3"
                                maxLength="16"
                                type="tel"
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onBlur={() => {
                                    onBlurPhoneNumber(
                                        Constant.ORG_PHONENUMBER,
                                        Constant.ORG_PHONE_AREA_CODE,
                                        Constant.ORG_PHONE3,
                                        Constant.ORG_PHONE4
                                    );
                                }}
                                value={applicationObj.OrgPhoneNumber || ""}
                                autoComplete="off"
                            />
                            <label htmlFor="OrgPhoneExt" className="mr-3">
                                Ext.
                            </label>
                            <input
                                id="OrgPhoneExt"
                                name="OrgPhoneExt"
                                className="form-control col-1 mr-3"
                                onChange={onChange}
                                value={applicationObj[`${Constant.ORG_PHONE_EXT}`]}
                                autoComplete="off"
                            />
                        </div>
                        {errors[`${Constant.ORG_PHONENUMBER}`] && (
                            <div className={`alert alert-danger col-2`}>
                                {errors[`${Constant.ORG_PHONENUMBER}`]}
                            </div>
                        )}
                    </div>
                </div>
                {/*End Organization phone number*/}

                {/*Organization level email*/}
                <div className="mt-3 card-body pb-3">
                    <span className="h5 font-weight-bold">
                        Organization Level Email (optional)
                    </span>
                    
                    <label htmlFor={Constant.ORG_EMAIL} className="accessible-hide mt-3">
                        Organization Level Email (optional)
                    </label>

                    <div className="mt-1 ml-3 mr-3">
                        <p className="mb-2">
                            <small>
                            If provided, this should be an email address that can be published on the DPRP Registry
                            and the CDC Find a Program Locator that participants, payers, and others may use to obtain
                            information about your program.
                            </small>
                        </p>
                        <div className="mb-0 pl-0 pr-0 col-4">
                            <TextInput
                                id={Constant.ORG_EMAIL}
                                onValidateBlur={onValidateBlur}
                                labelText={'Email Address'}
                                onChange={onChange}
                                name={Constant.ORG_EMAIL}
                                className="mb-0 form-control col-5 mr-3"
                                value={applicationObj[`${Constant.ORG_EMAIL}`] || ""}
                                autoComplete="off"
                            />
                        </div>
                        {errors[`${Constant.ORG_EMAIL}`] && (
                            <div className={`alert alert-danger col-4`}>
                                {errors[`${Constant.ORG_EMAIL}`]}
                            </div>
                        )}
                    </div>
                </div>
                {/*End Organization level email*/}

                {/*Organization Name */}
                <div className="card-body">
                    <OrganizationCategories
                        organizationCategories={organizationCategories}
                        onChange={onChange}
                        name={Constant.ORG_CATEGORIES}
                        value={applicationObj[`${Constant.ORG_CATEGORIES}`]}
                        error={errors[`${Constant.ORG_CATEGORIES}`]}
                    />
                </div>
                <div className="pr-3 pl-3 mb-3">
                    <div className="col">
                        <div className="custom-control">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                onChange={onCheckboxChange}
                                name={Constant.ORG_ADA_TYPE}
                                value={applicationObj[`${Constant.ORG_ADA_TYPE}`]}
                                id={Constant.ORG_ADA_TYPE}
                            />
                            <label className="pl-3" htmlFor={Constant.ORG_ADA_TYPE}>
                                My organization is also an ADA-recognized DSMES organization.
                            </label>
                            <span className="sr-only" aria-labelledby={Constant.ORG_ADA_TYPE}>{Constant.ORG_ADA_TYPE}</span>
                        </div>
                        <div className="custom-control">
                            <input
                                type="checkbox"
                                className="big-checkbox"
                                onChange={onCheckboxChange}
                                name={Constant.ORG_ADCES_TYPE}
                                value={applicationObj[`${Constant.ORG_ADCES_TYPE}`]}
                                id={Constant.ORG_ADCES_TYPE}
                            />
                            <label
                                className="pl-3"
                                htmlFor={Constant.ORG_ADCES_TYPE}
                            >
                                My organization is also an ADCES-accredited DSMES organization.
                            </label>
                            <span className="sr-only" aria-labelledby={Constant.ORG_ADCES_TYPE}>{Constant.ORG_ADCES_TYPE}</span>
                        </div>
                    </div>
                </div>
                {/*End Organization Name */}
            </div>
        </div>
    );
};

export default GeneralInfo;
