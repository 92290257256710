import React from 'react';
import Select from 'react-select';
import Constant from '../../common/Constant';
import ErrorMessage from '../../common/ErrorMessage.jsx';

const SelectMultiStateTerritory = ({
  onChange,
  onStateSelectChange,
  applicationObj,
  statesData,
  errors,
}) => {
  return (
    <>
      <p id="select-multi-state" className="mb-2">
        Please indicate the US states or territories whose residents you are
        open to for enrollment.
      </p>
      {!applicationObj[`${Constant.NATIONAL_ONLINE_PROVIDER}`] && (
        <>
          <div className="ml-3">
            <p className="font-italic mb-0 mt-2">
              start typing or select one or more states
            </p>
            <Select
              defaultValue=""
              isMulti
              aria-labelledby={'select-multi-state'}
              name={Constant.MULTI_STATE_TERRITORY_LIST}
              options={statesData}
              onChange={onStateSelectChange}
              value={applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`]}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.id}
              styles={Constant.COLOR_STYLES}
              isOptionDisabled={(option) =>
                option.description.toLowerCase() === 'us states' ||
                option.description.toLowerCase() === 'us territories' ||
                option.description.toLowerCase() === 'freely associated states'
              }
              className="basic-multi-select col-4 pl-0"
              classNamePrefix="select"
            />
          </div>
        </>
      )}
      {(errors[`${Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE}`] ||
        errors[`${Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE}`]) && (
        <ErrorMessage
          className="col-4"
          errorMessage={Constant.US_STATES_OR_TERRITOTIES_VALIDATE_MSG}
        />
      )}
    </>
  );
};

export default SelectMultiStateTerritory;
