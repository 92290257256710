import React from 'react';
import Constant from '../../common/Constant';
import RequiredSpanText from '../../common/RequiredSpanText';

function ProgramDeliveryModes({
  applicationObj,
  programDeliveryMode,
  loadingApiStatus,
  onChangeTypeOfDelivery,
  title,
  errors,
}) {
  return (
    <div>
      {loadingApiStatus ? (
        <h2>
          <strong>Loading Program Delivery Modes Data....</strong>
        </h2>
      ) : (
        <div>
          <span className="h5 font-weight-bold">
            <RequiredSpanText text={title} />
          </span>
          <div className="ml-3 mb-3" role="group" aria-label="delivery mode">
            <small className="">Select only one</small>
            {programDeliveryMode.map(({ id, name }, index) => {
              return (
                <div className="form-check ml-3" key={id}>
                  <label className="form-check-label" key={id}>
                    <input
                      type="radio"
                      name={Constant.DELIVERY_MODE}
                      onChange={onChangeTypeOfDelivery}
                      value={id || ''}
                      className="form-check-input"
                      defaultChecked={index === 0}
                      aria-checked={
                        applicationObj[`${Constant.DELIVERY_MODE}`] ===
                        String(id)
                      }
                    />
                    {name.toLowerCase().indexOf('person') === -1
                      ? name
                      : name + ' \u2020'}
                  </label>
                </div>
              );
            })}{' '}
            {errors[`${Constant.DELIVERY_MODE}`] && (
              <div className={`alert alert-danger col-3`}>
                {errors[`${Constant.DELIVERY_MODE}`]}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgramDeliveryModes;
