import React from 'react';
import Constant from '../../common/Constant';

const RegistryTable = ({
  registryData,
  onSort,
  isSearching,
  noDataFound,
  sortBy,
  onClickMultiOrg,
  toggleSortOrder,
}) => {
  const asc = '▲',
    desc = '▼';
  //get multiorg id to send back to registry.js table so it can be filter
  function setMultiOrgId(e) {
    e.preventDefault();
    const { className, attributes } = e.target;
    let elClassName = className,
      elId = attributes.orgid.value;
    //if it is the multiorg link, send the multiorg id to registry.js
    if (elClassName.includes('classTypeCol')) {
      onClickMultiOrg(elId);
    }
  }
  return (
    <table className="registryTable table-fixed table table-responsive table-striped">
      <caption className="accessible-hide">
        Table of all Recognized Organizations
      </caption>
      <thead className="bg-primary">
        <tr className="" role="row">
          <th
            role="columnheader"
            scope="col"
            className="OrganizationTh"
            onClick={() => {
              onSort(Constant.ORG_NAME);
            }}
            onKeyDown={(e) => e.key === 'Enter' && onSort(Constant.ORG_NAME)}
            tabIndex="0"
            aria-selected={sortBy !== Constant.ORG_NAME ? 'false' : 'true'}
          >
            Organization{' '}
            {sortBy !== Constant.ORG_NAME ? '' : toggleSortOrder ? asc : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            onClick={() => {
              onSort(Constant.FORMATTED_ADDRESS);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.FORMATTED_ADDRESS)
            }
            tabIndex="0"
            aria-selected={
              sortBy !== Constant.FORMATTED_ADDRESS ? 'false' : 'true'
            }
          >
            Address{' '}
            {sortBy !== Constant.FORMATTED_ADDRESS
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            className="phoneNumTh"
            onClick={() => {
              onSort(Constant.FORMATTED_PHONE);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.FORMATTED_PHONE)
            }
            tabIndex="0"
            aria-selected={
              sortBy !== Constant.FORMATTED_PHONE ? 'false' : 'true'
            }
          >
            Phone Number{' '}
            {sortBy !== Constant.FORMATTED_PHONE
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            className="phoneNumTh"
            onClick={() => {
              onSort(Constant.ORG_EMAIL_SORT);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.ORG_EMAIL_SORT)
            }
            tabIndex="0"
            aria-selected={
              sortBy !== Constant.ORG_EMAIL_SORT ? 'false' : 'true'
            }
          >
            Email{' '}
            {sortBy !== Constant.ORG_EMAIL_SORT
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            onClick={() => {
              onSort(Constant.WEBADDRESS);
            }}
            onKeyDown={(e) => e.key === 'Enter' && onSort(Constant.WEBADDRESS)}
            tabIndex="0"
            aria-selected={sortBy !== Constant.WEBADDRESS ? 'false' : 'true'}
          >
            Website{' '}
            {sortBy !== Constant.WEBADDRESS ? '' : toggleSortOrder ? asc : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            className="thClassType"
            onClick={() => {
              onSort(Constant.FORMATTED_CLASS_TYPE);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.FORMATTED_CLASS_TYPE)
            }
            tabIndex="0"
            aria-selected={
              sortBy !== Constant.FORMATTED_CLASS_TYPE ? 'false' : 'true'
            }
          >
            Available Class Type(s){' '}
            {sortBy !== Constant.FORMATTED_CLASS_TYPE
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            className="orgPublicStatus"
            onClick={() => {
              onSort(Constant.ORG_PUBLIC_STATUS);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.ORG_PUBLIC_STATUS)
            }
            tabIndex="0"
            aria-selected={
              sortBy !== Constant.ORG_PUBLIC_STATUS ? 'false' : 'true'
            }
          >
            Who can participate?{' '}
            {sortBy !== Constant.ORG_PUBLIC_STATUS
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
          <th
            role="columnheader"
            scope="col"
            onClick={() => {
              onSort(Constant.UMBRELLA_NAME);
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' && onSort(Constant.UMBRELLA_NAME)
            }
            tabIndex="0"
            aria-selected={sortBy !== Constant.UMBRELLA_NAME ? 'false' : 'true'}
          >
            Umbrella Hub Organization{' '}
            {sortBy !== Constant.UMBRELLA_NAME
              ? ''
              : toggleSortOrder
                ? asc
                : desc}
          </th>
        </tr>
      </thead>
      {isSearching ? (
        <tbody className="">
          <tr>
            <td className="form-inline">
              <span className="spinner-border text-info mr-2" />
              Searching for organization..
            </td>
          </tr>
        </tbody>
      ) : noDataFound ? (
        <tbody className="">
          <tr>
            <td>No organization found</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {registryData.map((registry) => {
            return (
              <tr key={registry.id}>
                <td
                  className="OrganizationTd"
                  dangerouslySetInnerHTML={{
                    __html: registry.isFullPlusRecognition
                      ? `<b><i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>&nbsp;${registry.orgName}`
                      : registry.isFullRecognition
                        ? `<b><i class="fa fa-star" aria-hidden="true"></i>&nbsp;${
                            registry.orgName
                          }`
                        : registry.isPreliminaryRecognition
                          ? `<b><i class="fa fa-circle" aria-hidden="true"></i>&nbsp;${
                              registry.orgName
                            }`
                          : registry.orgName,
                  }}
                />
                <td>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: registry.formattedAddress,
                    }}
                  ></span>
                  <div>
                    <span className="pr-1">{registry.city},</span>
                    <span className="pr-1">{registry.state}</span>
                    <span className="pr-1">{registry.formattedZip}</span>
                  </div>
                </td>
                <td className="small phoneNumTd">
                  {registry.formattedPhoneNumber}
                </td>
                <td>
                  {registry.orgEmail !== null && registry.orgEmail !== '' && (
                    <a href={`mailto:${registry.orgEmail}`}>
                      {registry.orgEmail}
                    </a>
                  )}
                </td>
                <td
                  className="registryWebAddress"
                  dangerouslySetInnerHTML={{ __html: registry.webAddress }}
                />
                <td
                  className="tdClassType font-weight-bold"
                  onClick={setMultiOrgId}
                  dangerouslySetInnerHTML={{
                    __html: registry.formattedClassType
                      .replaceAll(
                        '<a ',
                        "<a class='classTypeCol font-weight-light'"
                      )
                      .replaceAll('id', 'orgid'),
                  }}
                ></td>
                <td className="participantTypeTd">
                  {registry.allowSubmitInd === false
                    ? 'Non-Delivery Site'
                    : registry.orgPublicStatus}
                </td>
                <td>{registry.umbrellaName}</td>
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default RegistryTable;
