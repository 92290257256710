import React from 'react';
import '../../styles/ApplicationForm.scss';
import Constant from '../../common/Constant';
import RequiredSpanText from '../../common/RequiredSpanText';
import TextInput from '../../common/TextInput';

function ClassTypes({
  classType,
  applicationObj,
  onChange,
  onValidateBlur,
  onCheckBoxChange,
  loadingApiStatus,
  errors,
}) {
  return (
    <div className="mb-3">
      {loadingApiStatus ? (
        <h2>
          <strong>Loading Program Delivery Modes Data....</strong>
        </h2>
      ) : (
        <>
          <fieldset>
            <legend className="h5 font-weight-bold">
              <RequiredSpanText text="Class Type" />
            </legend>
            <div
              className="custom-control custom-checkbox"
              aria-labelledby="program-heading"
            >
              <span className="sr-only" id="program-heading">
                Program Delivery Class Type
              </span>
              <p className="mb-0 my-2">Select all that apply</p>
              {classType.map((lookUpItem, index) => {
                return (
                  <div
                    key={'ClassType' + lookUpItem.name}
                    className="custom-control"
                  >
                    <input
                      type="checkbox"
                      className="big-checkbox"
                      onChange={onCheckBoxChange}
                      name={'ClassType' + lookUpItem.name}
                      value={applicationObj[`ClassType${lookUpItem.name}`]}
                      id={'ClassType' + lookUpItem.name}
                    />
                    <label
                      className="pl-3"
                      htmlFor={'ClassType' + lookUpItem.name}
                    >
                      {lookUpItem.name}
                    </label>
                    <span
                      className="sr-only"
                      aria-labelledby={'ClassType' + lookUpItem.name}
                    >
                      {lookUpItem.name}
                    </span>
                  </div>
                );
              })}
              {applicationObj[`${Constant.CLASS_TYPE_OTHERS}`] === true && (
                <TextInput
                  onChange={onChange}
                  onValidateBlur={onValidateBlur}
                  value={
                    applicationObj[`${Constant.CLASS_TYPE_OTHER_NAME}`] || ''
                  }
                  labelClassName=""
                  labelText="Other"
                  accessibleHideLabel={'true'}
                  inlineText="Write in target audience served such as American
                    Indians/Alaskan Natives, patients, clients, etc."
                  name={Constant.CLASS_TYPE_OTHER_NAME}
                  inputClassName="col-3 float-left mr-3"
                  containerClassName="mb-0 ClassTypeOtherWrapper"
                  maxLength="100"
                  validateMsg={Constant.CLASS_TYPE_OTHER_NAME_VALIDATE_MSG}
                />
              )}
            </div>
            {errors[`${Constant.NO_CLASS_TYPE_SELECTED}`] && (
              <div className={`alert alert-danger col-4 ml-3`}>
                {errors[`${Constant.NO_CLASS_TYPE_SELECTED}`]}
              </div>
            )}
            {errors[`${Constant.CLASS_TYPE_OTHER_NAME}`] && (
              <div className={`alert alert-danger col-3 ml-4`}>
                {errors[`${Constant.CLASS_TYPE_OTHER_NAME}`]}
              </div>
            )}
          </fieldset>
        </>
      )}
    </div>
  );
}

export default ClassTypes;
