import React from 'react';
import Constant from '../../common/Constant';
import RequiredSpanText from '../../common/RequiredSpanText.jsx';
import TextInput from '../../common/TextInput.jsx';

function CoachTrainingEntityTypes({
  applicationObj,
  coachTrainingEntityType,
  trainingProvider,
  onChange,
  onValidateBlur,
  loadingApiStatus,
  onCheckBoxChange,
  errors,
}) {
  return (
    <div className="clear">
      {loadingApiStatus ? (
        <h2>
          <strong>Loading Coach Training Entity Typess Data....</strong>
        </h2>
      ) : (
        <>
          <span className="h5 font-weight-bold my-2">
            <RequiredSpanText text="Lifestyle Coach" />
          </span>
          <div
            className="mb-3"
            role="group"
            aria-label="Program Delivery Lifestyle Coach"
          >
            <span className="my-0 mb-2">
              <small className="ml-3">
                Provide the primary training mechanism the applicant
                organization will use or has used to train their main Lifestyle
                Coaches. Choose from:
                <ul className="numbered-list ml-3">
                  <li className="">
                    a private organization with a national network of program
                    sites whose Master Trainers were trained by an MOU-holding
                    training entity;
                  </li>
                  <li className="">
                    a CDC-recognized virtual organization with national reach
                    whose Master Trainers were trained by an MOU-holding
                    training entity; or
                  </li>
                  <li className="">
                    a Master Trainer (has completed at least 12 hours of formal
                    training as a Lifestyle Coach, successfully offered the
                    National DPP LCP for at least two years, completed a Master
                    Trainer program offered by an MOU-holding training entity,
                    and has a current agreement with the training entity to
                    serve as a Master Trainer).
                  </li>
                </ul>
              </small>
            </span>
            <div className="ml-3">
              <p className="mb-0 mb-2 mt-2">Select only one</p>
              {coachTrainingEntityType.map((lookUpItem, index) => {
                let linkNameStart = '';
                let linkName = '';
                let linkNameEnd = '';
                if (lookUpItem.id === 1) {
                  linkNameStart = lookUpItem.name.split(' National')[0];
                  linkName = lookUpItem.name.substring(
                    lookUpItem.name.indexOf('the') + 4,
                    lookUpItem.name.indexOf(' website')
                  );
                  linkNameEnd = lookUpItem.name.split(' Center')[1];
                }
                return (
                  <div key={lookUpItem.id} className="ml-4">
                    <input
                      type="radio"
                      className=""
                      onChange={onCheckBoxChange}
                      name={Constant.COACH_TRAINING_ENTITY_ID}
                      data-coach-training-entity-name={lookUpItem.name}
                      value={lookUpItem.id}
                      id={lookUpItem.id + 'lifeStyleCoach'}
                      defaultChecked={index === 0}
                      aria-checked={
                        applicationObj[
                          `${Constant.COACH_TRAINING_ENTITY_ID}`
                        ] === lookUpItem.id
                      }
                    />
                    {lookUpItem.id === 1 ? (
                      <label
                        className="ml-2"
                        htmlFor={lookUpItem.id + 'lifeStyleCoach'}
                      >
                        {linkNameStart}{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://nationaldppcsc.cdc.gov/s/article/Training-for-your-Lifestyle-Coaches"
                        >
                          {linkName}
                        </a>
                        {linkNameEnd}
                      </label>
                    ) : (
                      <label
                        className="ml-2"
                        htmlFor={lookUpItem.id + 'lifeStyleCoach'}
                      >
                        {lookUpItem.name}
                      </label>
                    )}
                  </div>
                );
              })}
            </div>
            {applicationObj[`${Constant.COACH_TRAINING_ENTITY_ID}`] === '4' && (
              <div className="ml-3">
                <TextInput
                  onChange={onChange}
                  onValidateBlur={onValidateBlur}
                  value={
                    applicationObj[`${Constant.COACH_TRAINING_ENTITY_NAME}`] ||
                    ''
                  }
                  name={Constant.COACH_TRAINING_ENTITY_NAME}
                  inputClassName="col-4"
                  containerClassName="ml-3 mt-2"
                  labelText="Name of Master Trainer"
                  requiredLabel={true}
                  error={errors[`${Constant.COACH_TRAINING_ENTITY_NAME}`]}
                  errorClassName="col-4"
                  maxLength="100"
                  validateMsg={Constant.COACH_TRAINING_ENTITY_VALIDATE_MSG}
                />
              </div>
            )}
            {applicationObj[`${Constant.COACH_TRAINING_ENTITY_ID}`] && (
              <>
                <label
                  className="ml-3 mt-2"
                  htmlFor={Constant.TRAINING_PROVIDER_ID}
                >
                  {applicationObj[`${Constant.COACH_TRAINING_ENTITY_ID}`] ===
                  '4'
                    ? 'Name of Master Trainer Program'
                    : 'Name of Training Provider'}{' '}
                  <span className="text-red">*</span>
                </label>
                <select
                  className="form-control col-4 ml-3 mb-3"
                  onChange={onChange}
                  name={Constant.TRAINING_PROVIDER_ID}
                  value={applicationObj[`${Constant.TRAINING_PROVIDER_ID}`]}
                  id={Constant.TRAINING_PROVIDER_ID}
                >
                  {trainingProvider.map(({ id, name }) => {
                    return (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {errors[`${Constant.TRAINING_PROVIDER_ID}`] && (
                  <div className="alert alert-danger col-4 ml-3">
                    {errors[`${Constant.TRAINING_PROVIDER_ID}`]}
                  </div>
                )}
              </>
            )}
            {(applicationObj[`${Constant.TRAINING_PROVIDER_ID}`] === '28' || //other options
              applicationObj[`${Constant.TRAINING_PROVIDER_ID}`] === '30') && ( //other options
              <div className="ml-3">
                <TextInput
                  onChange={onChange}
                  onValidateBlur={onValidateBlur}
                  value={
                    applicationObj[`${Constant.COACH_TRAINING_ENTITY_NAME}`] ||
                    ''
                  }
                  name={Constant.COACH_TRAINING_ENTITY_NAME}
                  inputClassName="col-4"
                  containerClassName="ml-3 mt-2 mb-3"
                  labelText="Please Specify"
                  requiredLabel={true}
                  error={errors[`${Constant.COACH_TRAINING_ENTITY_NAME}`]}
                  errorClassName="col-4"
                  maxLength="100"
                  validateMsg={Constant.COACT_TRAINING_PROVIDER_VALIDATE_MSG}
                />
              </div>
            )}
            <small className="ml-3">
              NOTE: The full list of MOU-holding training entities is found
              here:
              <a
                target="_blank"
                rel="noreferrer"
                className="ml-1"
                href="https://nationaldppcsc.cdc.gov/s/article/Training-for-your-Lifestyle-Coaches"
              >
                https://nationaldppcsc.cdc.gov/s/article/Training-for-your-Lifestyle-Coaches
              </a>
              .
            </small>
          </div>
        </>
      )}
    </div>
  );
}

export default CoachTrainingEntityTypes;
