import React from "react";
import Constant from "../../common/Constant";
import ReCAPTCHA from "react-google-recaptcha";

const VerificationAndSubmit = ({
    recaptchaRef
}) => {
    return (
        <div className="card">
            <h2 className="h4 card-header bg-primary">
                Verification & Submit Application
            </h2>
            <div className="card-body">
                <p>{Constant.VERIFIFCATION_AND_SUBMIT_TEXT_ONE}</p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: Constant.VERIFIFCATION_AND_SUBMIT_TEXT_TWO
                    }}
                />
                <ReCAPTCHA
                    sitekey="6LdqtwMqAAAAAPxO1DlltI32bS9rQE-19qdbi3-D"
                    size="invisible"
                    ref={recaptchaRef}
                />

                <button
                    id="btnSubmitApplicaiton"
                    className="btn btn-primary"
                >
                    Submit Application
                </button>
            </div>
        </div>
    );
};

export default VerificationAndSubmit;
