import Constant from '../common/Constant';
import { validateEmail, emailHasDomain } from '../common/Util';

export function formIsValid(applicationObj) {
  const _errors = {};
  function validateField(field, validateMsg) {
    if (!applicationObj[`${field}`]) _errors[field] = validateMsg;
  }
  function validateContactInfo(
    fName,
    fNameMsg,
    lName,
    lNameMsg,
    title,
    titleMsg,
    phone,
    phoneMsg,
    email,
    emailMsg,
    verifyEmail,
    verifyEmailFailMsg,
    verifyEmailMsg
  ) {
    if (!applicationObj[`${fName}`]) _errors[fName] = fNameMsg;
    if (!applicationObj[`${lName}`]) _errors[lName] = lNameMsg;
    // if (
    //   applicationObj[`${Constant.DATA_PREPARER_TYPE}`] ===
    //     Constant.DATA_PREPARER_NEW_CONTACT_TXT &&
    //   applicationObj[`${Constant.INCLUDE_DATA_PREPARER}`] === true
    // ) {
    //   if (!applicationObj[`${title}`]) _errors[title] = titleMsg;
    // }
    if (!applicationObj[`${phone}`]) _errors[phone] = phoneMsg;
    if (!applicationObj[`${email}`]) _errors[email] = emailMsg;
    if (
      applicationObj[`${email}`] &&
      applicationObj[`${email}`].length > 0 &&
      !validateEmail(applicationObj[`${email}`])
    )
      _errors[email] = 'Incorrect Email Format';
    //verify for matching email
    if (
      applicationObj[`${email}`] &&
      applicationObj[`${verifyEmail}`] &&
      applicationObj[`${email}`] !== applicationObj[`${verifyEmail}`]
    )
      _errors[verifyEmail] = verifyEmailFailMsg;

    if (!applicationObj[`${verifyEmail}`])
      _errors[verifyEmail] = verifyEmailMsg;
  }
  //Before you begin section
  if (applicationObj[`${Constant.ACKNOWLEDGE_SOP_IND}`] === false) {
    _errors[Constant.ACKNOWLEDGE_SOP_IND] =
      Constant.ACKNOWLEDGE_SOP_IND_VALIDATE_MSG;
  }
  if (applicationObj[`${Constant.ACKNOWLEDGE_OCA_IND}`] === false) {
    _errors[Constant.ACKNOWLEDGE_OCA_IND] =
      Constant.ACKNOWLEDGE_OCA_IND_VALIDATE_MSG;
  }
  if (applicationObj[`${Constant.ACKNOWLEDGE_HEGPIC_IND}`] === false) {
    _errors[Constant.ACKNOWLEDGE_HEGPIC_IND] =
      Constant.ACKNOWLEDGE_HEGPIC_IND_VALIDATE_MSG;
  }
  //General Info Validate
  if (applicationObj[`${Constant.REAPPLY}`] === true) {
    const prevOrgCode = applicationObj[`${Constant.PREV_ASSIGNED_ORG_CODE}`];
    //if applicaiton type is Re-Apply, Previous Orgcode is required
    if (prevOrgCode === 0) {
      _errors[Constant.PREV_ASSIGNED_ORG_CODE] =
        Constant.PREV_ASSIGNED_ORG_CODE_VALIDATE_MSG;
    }
  }
  if (
    applicationObj[`${Constant.ORG_EMAIL}`].length > 0 &&
    !validateEmail(applicationObj[`${Constant.ORG_EMAIL}`])
  ) {
    _errors[Constant.ORG_EMAIL] = Constant.EMAIL_NOT_VALID_MSG;
  } else if (
    applicationObj[`${Constant.ORG_EMAIL}`].length > 0 &&
    emailHasDomain(
      applicationObj[`${Constant.ORG_EMAIL}`],
      Constant.ORG_EMAIL_INVALID_DOMAIN
    )
  ) {
    _errors[Constant.ORG_EMAIL] = Constant.ORG_EMAIL_DOMAIN_VALIDATION_MSG;
  }
  validateField(Constant.ORG_NAME, Constant.ORG_NAME_VALIDATE_MSG);
  validateField(
    Constant.PHYSICAL_ADDRESS,
    Constant.PHYSICAL_ADDRESS_VALIDATE_MSG
  );
  validateField(
    Constant.PHYSICAL_STATE_ID,
    Constant.PHYSICAL_STATE_ID_VALIDATE_MSG
  );
  validateField(Constant.PHYSICAL_CITY, Constant.PHYSICAL_CITY_VALIDATE_MSG);
  validateField(Constant.PHYSICAL_ZIP5, Constant.PHYSICAL_ZIP5_VALIDATE_MSG);
  validateField(
    Constant.ORG_PHONENUMBER,
    Constant.ORG_PHONENUMBER_VALIDATE_MSG
  );
  validateField(Constant.ORG_CATEGORIES, Constant.ORG_CATEGORIES_VALIDATE_MSG);
  //End General Info Validate
  //Type of Delivery Validate
  validateField(Constant.DELIVERY_MODE, Constant.DELIVERY_MODE_VALIDATE_MSG);
  if (
    applicationObj[`${Constant.DELIVERY_MODE}`] !== '1' &&
    applicationObj[`${Constant.ENROLLMENT_LOCATION}`] === ''
  ) {
    _errors[Constant.ENROLLMENT_LOCATION] =
      Constant.ENROLLMENT_LOCATION_VALIDATE_MSG;
  }
  if (
    applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() === 'false' &&
    applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`].length <= 0
  ) {
    _errors[Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE] =
      Constant.US_STATES_OR_TERRITOTIES_VALIDATE_MSG;
  }
  //if delivery mode is online (id=2)
  if (
    applicationObj[`${Constant.DELIVERY_MODE}`] == '2' || // eslint-disable-line
    applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].find(
      (id) => id === 2
    )
  ) {
    if (
      !applicationObj[`${Constant.NATIONAL_ONLINE_PROVIDER}`] &&
      applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() ===
        'false' &&
      applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`].length <= 0
    ) {
      _errors[Constant.US_STATES_OR_TERRITOTIES_OL_VALIDATE] =
        Constant.US_STATES_OR_TERRITOTIES_VALIDATE_MSG;
    }
  }
  //if delivery mode is distance learning (id=3)
  if (
    applicationObj[`${Constant.DELIVERY_MODE}`] === '3' ||
    applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].find(
      (id) => id === 3
    )
  ) {
    //eslint-disable-next-line
    if (applicationObj[`${Constant.ENROLLMENT_LOCATION}`] === '') {
      _errors[Constant.ENROLLMENT_LOCATION] =
        Constant.ENROLLMENT_LOCATION_VALIDATE_MSG;
    }
    if (
      !applicationObj[`${Constant.NATIONAL_ONLINE_PROVIDER}`] &&
      applicationObj[`${Constant.ENROLLMENT_LOCATION}`].toString() ===
        'false' &&
      applicationObj[`${Constant.MULTI_STATE_TERRITORY_LIST}`].length <= 0
    ) {
      _errors[Constant.US_STATES_OR_TERRITOTIES_DL_VALIDATE] =
        Constant.US_STATES_OR_TERRITOTIES_VALIDATE_MSG;
    }
  }
  if (applicationObj[`${Constant.DELIVERY_MODE}`] === '4') {
    if (
      applicationObj[`${Constant.PARTICIPANT_DELIVERY_MODE_IDS}`].length <= 1
    ) {
      _errors[Constant.COMBINATION_DELIVERY_MODE_VALIDATE] =
        Constant.COMBINATION_DELIVERY_MODE_VALIDATE_MSG;
    }
  }
  //Delivery attestation validate
  if (applicationObj[`${Constant.DELIVERY_ATTESTATION}`] === false) {
    _errors[Constant.DELIVERY_ATTESTATION] =
      Constant.DELIVERY_ATTESTATION_VALIDATE_MSG;
  }
  //End Type of Delivery Validate
  //Program Coordinator Validate
  validateContactInfo(
    Constant.CONTACT_FIRST_NAME,
    Constant.CONTACT_FIRST_NAME_VALIDATE_MSG,
    Constant.CONTACT_LAST_NAME,
    Constant.CONTACT_LAST_NAME_VALIDATE_MSG,
    Constant.CONTACT_TITLE,
    Constant.CONTACT_TITLE_VALIDATE_MSG,
    Constant.CONTACT_PHONE,
    Constant.CONTACT_PHONE_VALIDATE_MSG,
    Constant.CONTACT_EMAIL,
    Constant.CONTACT_EMAIL_VALIDATE_MSG,
    Constant.CONTACT_EMAIL_VERIFY,
    Constant.CONTACT_EMAIL_VERIFY_FAIL_MSG,
    Constant.CONTACT_EMAIL_EMAIL_VERIFY_VALIDATE_MSG
  );
  //End Program Coordinator Validate
  if (!applicationObj[`${Constant.CONTACT_TITLE}`]) {
    _errors[Constant.CONTACT_TITLE] = Constant.CONTACT_TITLE_VALIDATE_MSG;
  }
  if (
    applicationObj[`${Constant.CONTACT_TITLE}`] === 14 &&
    applicationObj[`${Constant.CONTACT_TITLE_OTHER}`] === ''
  ) {
    _errors[Constant.CONTACT_TITLE_OTHER] = Constant.OTHER_TITLE_REQUIRED;
  }
  //secondary contact
  if (applicationObj[`${Constant.INCLUDE_SECONDARY_CONTACT}`]) {
    validateContactInfo(
      Constant.SECONDARY_CONTACT_FIRST_NAME,
      Constant.CONTACT_FIRST_NAME_VALIDATE_MSG,
      Constant.SECONDARY_CONTACT_LAST_NAME,
      Constant.CONTACT_LAST_NAME_VALIDATE_MSG,
      Constant.SECONDARY_CONTACT_TITLE,
      Constant.CONTACT_TITLE_VALIDATE_MSG,
      Constant.SECONDARY_CONTACT_PHONE,
      Constant.CONTACT_PHONE_VALIDATE_MSG,
      Constant.SECONDARY_CONTACT_CONTACT_EMAIL,
      Constant.CONTACT_EMAIL_VALIDATE_MSG,
      Constant.SECONDARY_CONTACT_CONTACT_EMAIL_VERIFY,
      Constant.CONTACT_EMAIL_VERIFY_FAIL_MSG,
      Constant.CONTACT_EMAIL_EMAIL_VERIFY_VALIDATE_MSG
    );
    if (
      applicationObj[`${Constant.SECONDARY_CONTACT_TITLE}`] === 14 &&
      applicationObj[`${Constant.SECONDARY_CONTACT_TITLE_OTHER}`] === ''
    ) {
      _errors[Constant.SECONDARY_CONTACT_TITLE_OTHER] =
        Constant.OTHER_TITLE_REQUIRED;
    }
    if (applicationObj[`${Constant.SECONDARY_CONTACT_TITLE}`] === null) {
      _errors[Constant.CONTACT_TITLE] = Constant.CONTACT_TITLE_VALIDATE_MSG;
    }
  }
  // test for unique email
  if (
    applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`] !== '' &&
    (String(applicationObj[`${Constant.CONTACT_EMAIL}`]).toLowerCase() ===
      String(
        applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`]
      ).toLowerCase() ||
      String(
        applicationObj[`${Constant.SECONDARY_CONTACT_CONTACT_EMAIL}`]
      ).toLowerCase() ===
        String(applicationObj[`${Constant.DATA_PREPARER_EMAIL}`]).toLowerCase())
  ) {
    _errors[Constant.SECONDARY_CONTACT_EMAIL_UNIQUE] =
      Constant.EMAIL_UNIQUE_FAIL_TXT;
  }
  //end secondary contact
  //data preparer
  if (applicationObj[`${Constant.INCLUDE_DATA_PREPARER}`]) {
    validateContactInfo(
      Constant.DATA_PREPARER_FIRST_NAME,
      Constant.CONTACT_FIRST_NAME_VALIDATE_MSG,
      Constant.DATA_PREPARER_LAST_NAME,
      Constant.CONTACT_LAST_NAME_VALIDATE_MSG,
      Constant.DATA_PREPARER_TITLE,
      Constant.DATA_PREPARER_AFFLICATION_VALIDATE_MSG,
      Constant.DATA_PREPARER_PHONE,
      Constant.CONTACT_PHONE_VALIDATE_MSG,
      Constant.DATA_PREPARER_EMAIL,
      Constant.CONTACT_EMAIL_VALIDATE_MSG,
      Constant.DATA_PREPARER_EMAIL_VERIFY,
      Constant.CONTACT_EMAIL_VERIFY_FAIL_MSG,
      Constant.CONTACT_EMAIL_EMAIL_VERIFY_VALIDATE_MSG
    );
    if (
      applicationObj[`${Constant.DATA_PREPARER_TITLE}`] === 14 &&
      applicationObj[`${Constant.DATA_PREPARER_AFFLICATION}`] === ''
    ) {
      _errors[Constant.DATA_PREPARER_AFFLICATION] =
        Constant.OTHER_TITLE_REQUIRED;
    }
    if (applicationObj[`${Constant.DATA_PREPARER_TITLE}`] === null) {
      _errors[Constant.DATA_PREPARER_TITLE] = Constant.CONTACT_TITLE_VALIDATE_MSG;
    }
  }
  if (
    (applicationObj[`${Constant.DATA_PREPARER_EMAIL}`] !== '' &&
      String(applicationObj[`${Constant.CONTACT_EMAIL}`]).toLowerCase() ===
        String(
          applicationObj[`${Constant.DATA_PREPARER_EMAIL}`]
        ).toLowerCase()) ||
    String(applicationObj[`${Constant.DATA_PREPARER_EMAIL}`]).toLowerCase() ===
      String(
        applicationObj[`${Constant.SECONDARY_CONTACT_EMAIL_UNIQUE}`]
      ).toLowerCase()
  ) {
    _errors[Constant.DATA_PREPARER_EMAIL_UNIQUE] =
      Constant.EMAIL_UNIQUE_FAIL_TXT;
    //end data preparer
  }
  //Program Delivery Information
  if (applicationObj[`${Constant.CLASS_TYPE_OTHERS}`]) {
    validateField(
      Constant.CLASS_TYPE_OTHER_NAME,
      Constant.CLASS_TYPE_OTHER_NAME_VALIDATE_MSG
    );
  }
  if (
    !applicationObj[`${Constant.CLASS_TYPE_EMPLOYEES}`] &&
    !applicationObj[`${Constant.CLASS_TYPE_PUBLIC}`] &&
    !applicationObj[`${Constant.CLASS_TYPE_MEMBERS}`] &&
    !applicationObj[`${Constant.CLASS_TYPE_OTHERS}`]
  )
    _errors[Constant.NO_CLASS_TYPE_SELECTED] = Constant.CLASS_TYPE_VALIDATE_MSG;
  //training entity
  validateField(
    Constant.COACH_TRAINING_ENTITY_ID,
    Constant.COACH_TRAINING_ENTITY_ID_VALIDATE_MSG
  );
  //validate if training entity id has been selected
  if (applicationObj[`${Constant.TRAINING_PROVIDER_ID}`] === 0) {
    _errors[Constant.TRAINING_PROVIDER_ID] =
      Constant.COACH_TRAINING_ENTITY_ID_VALIDATE_MSG;
  }
  //validate coach training entity name only whenA Master Trainer (MT) who completed a MT program or OTHER is selected from the Lifestyle Coach section
  if (
    applicationObj[`${Constant.COACH_TRAINING_ENTITY_ID}`] === '4' || //A Master Trainer (MT) who completed a MT program
    applicationObj[`${Constant.TRAINING_PROVIDER_ID}`] === '28' || //other options
    applicationObj[`${Constant.TRAINING_PROVIDER_ID}`] === '30'
  ) {
    //other options
    validateField(
      Constant.COACH_TRAINING_ENTITY_NAME,
      applicationObj[`${Constant.COACH_TRAINING_ENTITY_ID}`] === '4'
        ? Constant.COACH_TRAINING_ENTITY_VALIDATE_MSG
        : Constant.COACT_TRAINING_PROVIDER_VALIDATE_MSG
    );
  }
  //Projected start date radio button validation
  if (applicationObj[`${Constant.INCLUDE_PROJECTED_START_DATE}`] === '') {
    _errors[Constant.INCLUDE_PROJECTED_START_DATE] =
      Constant.INCLUDE_PROJECTED_START_DATE_VALIDATE_MSG;
  }
  //Include additional content radio button validation
  if (applicationObj[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`] === '') {
    _errors[Constant.IS_ADDITIONAL_CONTENT_FILE] =
      Constant.INCLUDE_ADDITIONAL_CONTENT_FILE_VALIDATE_MSG;
  }
  //validate other curriculum files
  if (applicationObj[`${Constant.CURRICULUM}`] === '9999') {
    if (
      applicationObj[`${Constant.FILE_SIZE_MB}`] > 0 &&
      applicationObj[`${Constant.FILE_UPLOAD}`] &&
      applicationObj[`${Constant.CURRICULUM_FILE_OVER_SIZE}`] === false
    ) {
      _errors[Constant.FILE_UPLOAD] = Constant.FILE_UPLOAD_VALIDATE_MSG_TWO; //file does not meet requirements
    } else if (
      (applicationObj[`${Constant.FILE_SIZE_MB}`] === '0' ||
        applicationObj[`${Constant.FILE_SIZE_MB}`] === undefined) &&
      applicationObj[`${Constant.CURRICULUM_FILE_OVER_SIZE}`] === false
    ) {
      _errors[Constant.FILE_UPLOAD] = Constant.FILE_UPLOAD_VALIDATE_MSG;
    }
    validateField(
      Constant.CURRICULUM_NAME,
      Constant.CURRICULUM_NAME_VALIDATE_MSG
    );
    validateField(
      Constant.CURRICULUM_DESCRIPTION,
      Constant.CURRICULUM_DESCRIPTION_VALIDATE_MSG
    );
  }
  //validate supplemental files
  //IS_ADDITIONAL_CONTENT_FILE
  if (
    applicationObj[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`] === true &&
    applicationObj[`${Constant.SUPPLEMENTAL_FILE_OVERSIZE}`] === false &&
    (applicationObj[`${Constant.FILE_SIZE_MB}`] === '0' ||
      applicationObj[`${Constant.FILE_SIZE_MB}`] === undefined)
  ) {
    _errors[Constant.FILE_UPLOAD] =
      Constant.IS_ADDITIONAL_CONTENT_FILE_VALIDATE_MSG;
  }
  validateField(Constant.CURRICULUM, Constant.CURRICULUM_VALIDATE_MSG);
  //validate if project start date is yes
  if (
    applicationObj[`${Constant.INCLUDE_PROJECTED_START_DATE}`] === true &&
    applicationObj[`${Constant.PROJECTED_START_DATE}`] === null
  ) {
    _errors[Constant.PROJECTED_START_DATE] =
      Constant.PROJECTED_START_DATE_VALIDATE_MSG;
  }
  //end Program Delivery Information
  //certification of application
  validateField(
    Constant.ELECTRONIC_SIG_NAME,
    Constant.ELECTRONIC_SIG_NAME_VALIDATE_MSG
  );
  validateField(
    Constant.ELECTRONIC_SIG_TITLE,
    Constant.ELECTRONIC_SIG_TITLE_VALIDATE_MSG
  );
  validateField(
    Constant.ELECTRONIC_ORG_NAME,
    Constant.ELECTRONIC_ORG_NAME_VALIDATE_MSG
  );
  //end certification of application
  //return all errors back to ui
  return _errors;
}
