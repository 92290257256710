import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
    Routes,
    Route
} from "react-router";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import "./styles/tp4Override.scss";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/font-awesome/css/font-awesome.css";
import "./index.scss";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter basename={baseUrl.toLocaleLowerCase()}>
        <Routes>
            <Route path="*" element={<App />}>
            </Route>
        </Routes>
    </BrowserRouter>
);

//registerServiceWorker()
